<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Теги товара {{ itemCode }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            disabled
            outlined
          />
          <v-autocomplete
            v-model="form.data.tags"
            label="Теги"
            hint="Выберите основные теги, описывающие данный товар"
            persistent-hint
            multiple
            chips
            :items="tags"
            item-text="name"
            item-value="id"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click:close="remove(data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.back()">Назад</v-btn>
          <!--          <v-btn :to="{name: 'item.view', params: { id }}">Отмена</v-btn>-->
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ItemUpdateForm from '@/model/item/ItemUpdateForm'
import ToastService from '@/service/ToastService'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemTagEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          tags: []
        }
      }
    }
  },
  computed: {
    ...mapState('item_tag_edit', ['item', 'tags', 'busy']),
    itemCode() {
      if (this.item.codeBs) {
        return this.item.code + ' (' + this.item.codeBs + ')'
      }

      return this.item.code
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.tags = this.item.tags.map((t) => t.id)
      })
  },
  methods: {
    ...mapActions('item_tag_edit', ['fetchData', 'updateItemTags']),
    submitForm() {
      this.updateItemTags({ tagIds: this.form.data.tags })
        // .then(() => this.$router.push({ name: 'item.view', params: { id: this.id } }))
        .then(() => this.$router.back())
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    remove(item) {
      const index = this.form.data.tags.indexOf(item.id)

      if (index >= 0) {
        this.form.data.tags.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
